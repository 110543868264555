@import "../../css/vars";
@import "../../css/typo";

.loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    transition: 0.4s 1s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.loader.__loaded {
    pointer-events: none;
    opacity: 0;
    .loader__box {
        animation: loaderRotate 1s forwards reverse 2;
        span {
            scale: 2;
            animation: loaderRotate 1s forwards 2;
            background-color: $color_primary;
        }
    }
}
.loader__box {
    width: 4rem;
    height: 4rem;
    position: absolute;
    display: flex;
    animation: loaderRotate 1s infinite reverse;
    span {
        display: block;
        position: absolute;
        width: 1rem;
        height: 1rem;
        background-color: #feaed8;
        transition: .4s;
        animation: loaderRotate 1s infinite;
        &:nth-child(1) {
            top: 0;
            left: 0;
            transform-origin: left top;
        }
        &:nth-child(2) {
            top: 0;
            right: 0;
            transform-origin: top right;
        }
        &:nth-child(3) {
            left: 0;
            bottom: 0;
            transform-origin: left bottom;
        }
        &:nth-child(4) {
            right: 0;
            bottom: 0;
            transform-origin: right bottom;
        }
    }
}
@keyframes loaderRotate {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}
