@import "../../css/vars";
@import "../../css/typo";

.rotator {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 400;
    opacity: 0;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("../../../public/assets/bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $color_bg;
    background-attachment: fixed;
    display: none;
    pointer-events: none;
    @media (max-width: $tablet) {
        display: flex;
    }
    
    &.__active {
        @media (max-width: $tablet) {
            pointer-events: all;
            opacity: 1;
        }
    }
    .rotator__image {
        flex-grow: 1;
        max-height: 60vh;
        margin-bottom: 2rem;
        animation: rotatorImage 2s infinite cubic-bezier(0.5, -0.5, 0.5, 1.5);
        .image {
            height: 100%;
        }
    }
    .rotator__title {
        color: $color_green;
        text-align: center;
    }
}

@keyframes rotatorImage {
    0% {
        opacity: 0;
        transform: rotate(0);
    }
    15% {
        opacity: 1;
        transform: rotate(0);
    }
    85% {
        opacity: 1;
        transform: rotate(90deg);
    }
    100% {
        opacity: 0;
        transform: rotate(90deg);
    }
}
