@import "../../css/vars";
@import "../../css/typo";

.mainTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    &:not(.__level){
        margin-top: -6em;
    }
    &.__level {
        width: 600px;
        max-width: 100%;
        .mainTitle__lottie {
            width: 60vmax;
            max-width: 100%;
        }
    }
    .image {
        display: flex;
        justify-content: center;
        svg path,
        svg>g,
        svg circle {
            animation-name: shake;
            animation-iteration-count: infinite;
            transform-origin: center center;
            animation-timing-function: steps(1);
            @for $i from 1 to 30 {
                &:nth-child(#{$i}) {
                    $dur: random($limit: (200));
                    $del: random($limit: (200));
                    animation-duration: ($dur / 50 + 2) + s;
                    animation-delay: ($del / 50 - 2) + s;
                }
            }
        }
    }
}

@keyframes shake {
    0%{
        transform: translate(0, 0) rotate(0) scale(1);
    }
    20%{
        transform: translate(0.5%, -0.5%) rotate(0.7deg) scale(1.01);
    }
    40% {
        transform: translate(-0.5%, -0.5%) rotate(-0.7deg) scale(0.99);
    }
    60%{
        transform: translate(0.5%, 0.5%) rotate(0.7deg) scale(1.01);
    }
    80%{
        transform: translate(0%, -0.5%) rotate(-0.7deg) scale(0.99);
    }
    100%{
        transform: translate(0, 0) rotate(0);
    }
}