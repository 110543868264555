@import "../../css/vars";
@import "../../css/typo";

.nonRelease {
    position: fixed;
    z-index: 3000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100vmin;
    height: 100vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8rem;
    text-align: center;
    color: $color_white;
    font-weight: bold;
    pointer-events: none;
    transform: rotate(-45deg);
    opacity: 0.2;
}

.analitycs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    pointer-events: none;
    background: #0000007f;
    padding: 4px 4px;
    text-align: center;
    font-size: 1rem;
    .grad {
        margin-top: 4px;
        background: linear-gradient(
            to right,
            red,
            25px,
            green 30px,
            blue,
            pink,
            yellow,
            purple,
            purple calc(100% - 30px),
            red calc(100% - 25px),
            red
        );
        width: 100%;
        height: 10px;
    }
}
