@import "../../css/vars";
@import "../../css/typo";

.page.game {
    position: relative;
    // padding-bottom: 20vw;
    justify-content: center;
    &.__loaded {
        .gameCompleteLevel__container {
            & > * {
                opacity: 1;
            }
        }
    }
    .gameCompleteLevel__container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-start;
        &.__active {
            .botBg {
                opacity: 1;
                transform: none;
            }
        }
        .gameCompleteLevel {
        }
        .botBg {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: 0.4s;
            transform: translateY(100%);
            opacity: 0;
            display: flex;
            justify-content: center;

            max-height: 40vh;

            // &.__hidden {
            //     opacity: 0;
            // }
        }
        .actions {
            display: flex;
            @include actions();
            margin-top: calc(2rem + 2vmax);
            justify-content: center;
            // padding-left: 20%;
        }
    }
}
