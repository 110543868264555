@import "../../css/vars";
@import "../../css/typo";


.gameCompleteLevel__container {
    pointer-events: none;
    opacity: 0;
    transition: .4s;
    &.__active {
        pointer-events: all;
        opacity: 1;
    }
}

.gameCompleteLevel {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .actions {
        &>*{
            &:first-child:not(:last-child){
                margin-right: 5rem;
            }
            &:first-child:last-child{
                
            }
        }
    }
}
