@import "../../css/vars";
@import "../../css/typo";

.btn {
    user-select: none;
    cursor: pointer;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    border: none;
    position: relative;
    border-radius: 0.5rem;
    padding: 0.75rem 2rem;
    @include transition();
    @include t1();
    &:before,
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .1s;
        opacity: 0;
        pointer-events: none;
        user-select: none;
    }
    &:before {
        content: '';
        background-color: transparentize($color_white, 0.6);
    }
    &:after {
        content: "load";
    }
    &.__primary {
        color: $color_white;
        background-color: $color_primary;
        padding: 0.75rem;

        // &:focus,
        &:hover {
            background-color: $color_primary_hover;
        }
        &:active {
            background-color: $color_primary_active;
        }
    }
    &.__secondary {
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 0.6;
        }
    }
    &.__download,
    &.__share {
        background-color: $color_white;
        border-radius: 500px;
        width: 7rem;
        height: 7rem;
        &:hover {
            background-color: $color_primary_hover;
            .icon svg * {
                transition: .05s;
                stroke: $color_white
            }
        }
        &:active {
            background-color: $color_primary_active;
            .icon svg * {
                transition: .02s;
                stroke: $color_white
            }
        }
        .icon svg * {
            transition: .2s;
        }
    }
    &.__icon {
        .icon {
            margin-right: 0;
        }
    }
    &.__loading {
        pointer-events: none;
        &:before,
        &:after {
            opacity: 1;
        }
        &:after {
            animation: rotate 1s infinite;
        }
    }
    .icon {
        display: flex;
        margin-right: 0.75rem;
    }
    &.__fill {
        flex-grow: 1;
    }
    &.__disabled,
    &.__noActions {
        pointer-events: none;
    }
    &.__disabled {
        color: $color_white;
        background-color: $color_gray;
    }
    &:focus,
    &:hover {
        opacity: 1;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.btn__wrapper {
    perspective: 140px;
    display: inline-flex;
    padding: 6px 10px;
    padding-right: 0;
    .btn {
        transform: rotateY(10deg);
        transform-style: preserve-3d;
        backface-visibility: hidden;
        border: 1px solid rgb(46, 46, 46);
        // outline: 1px solid black;
        @media(max-width: $mobile){
            max-width: 120px;
        }
    }
}