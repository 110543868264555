@import "../../css/vars";
@import "../../css/typo";

.text {
    &.tNumbers {
        @include tNumbers();
    }
    &.t1 {
        @include t1();
    }
    &.t2 {
        @include t2();
    }
    &.t3 {
        @include t3();
    }

    &.__align_left {
        text-align: left;
    }
    &.__align_center {
        text-align: center;
    }
    &.__align_right {
        text-align: right;
    }
}

.title {
    &.h1 {
        @include h1();
    }
    &.h2 {
        @include h2();
    }
    &.h3 {
        @include h3();
    }
    &.h4 {
        @include h4();
    }

    &.__align_left {
        text-align: left;
    }
    &.__align_center {
        text-align: center;
    }
    &.__align_right {
        text-align: right;
    }
}
