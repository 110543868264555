@import "../../css/vars";
@import "../../css/typo";

footer {
    background-color: $color_black;
    padding: 4rem 0;
    display: none;
    &.__active {
        display: flex;
    }

    .logoFull {
        display: flex;
        .image {
            width: 218px;
            @media(max-width: $tablet){
                width: 180px;
            }
            @media(max-width: $mobile){
                width: 120px;
            }
        }
    }
    .logoExt {
        display: flex;
    }

    .footer__inner {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }
    .footer__row {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
    }
    .footer__col {
        flex-grow: 1;
    }
    .footer__menu {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .footer__section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media(max-width: $tablet){
            flex-basis: calc(50% - 0.5rem);
        }
        .footer__section_title {
            @include h4();
            color: $color_gray;
        }
        .footer__section_list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        .footer__section_item {
            @include t1();
            color: $color_white;
            text-transform: none;
        }
    }
}
