@import "../../css/vars";
@import "../../css/typo";

.gameBox {
    display: flex;
    flex-grow: 1;
    position: relative;
    pointer-events: none;
    opacity: 0;
    transition: 0.4s;
    user-select: none;
    @media (max-width: $tablet) {
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
    }
    &.__active {
        pointer-events: all;
        opacity: 01;
        &.__correct_done {
            @media (max-width: $tablet) {
                .grid {
                    opacity: 0;
                }
                .gameBox__stock {
                    opacity: 0;
                }
            }
        }
        .gameBox__stock_image {
            opacity: 1;
        }
    }
    &.__loading {
        .gameBox__grid {
            opacity: 0;
            transition: all 0s, opacity 0.4s;
        }
        .gameBox__stock {
            opacity: 0;
        }
        .gameBox__stock_image {
            opacity: 0;
        }
    }
    &.__correct {
        .grid {
            pointer-events: none;
            .place {
                animation: wordCorrectPlace 0.6s forwards ease-in-out;
                @for $i from 1 to 40 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i / 10 + s;
                        animation-delay: $i / 10 + s;
                        &.__blocked {
                            border: 1px solid transparent;
                        }
                        .gameBox__char {
                            animation: wordCorrectChar 1s infinite ease-in-out;
                            transition-delay: $i / 10 + s;
                            animation-delay: $i / 10 + s;
                            color: $color_white;
                            .image svg * {
                                transition-delay: $i / 10 + s;
                                fill: $color_green;
                            }
                        }
                    }
                }
            }
        }
        @keyframes wordCorrectPlace {
            0% {
                background: none;
                scale: 1;
            }
            20% {
                background-color: transparentize($color_green, 0.6);
                scale: 0.7;
            }
            100% {
                background: none;
            }
        }
        @keyframes wordCorrectChar {
            0% {
                scale: 1;
                rotate: 0;
            }
            33% {
                rotate: -10deg;
            }
            50% {
                scale: 0.9;
            }
            66% {
                rotate: 10deg;
            }
            100% {
                scale: 1;
                rotate: 0;
            }
        }
    }
    &.__learn_1 {
        .gameBox__gridItem {
            background-color: transparentize($color_gray, 0.5);
            animation: pulse 1s infinite;
        }
        .stock {
            .gameBox__char {
                &:not(.__dragging) {
                    animation: pulse 1s infinite;
                }
            }
        }
    }
    &.__learn_2 {
    }
    &.__learn_3 {
        .exampleChar {
            animation: pulse 1s infinite;
        }
    }
    .gameBox__col {
        flex-basis: 100%;
        margin: 1rem;
        margin-bottom: 0;
        position: relative;
        width: 100%;
        &.__main {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @media (max-width: $tablet) {
                justify-content: center;
                flex-grow: 1;
            }
        }
        &.__stock {
            max-width: 60vmax;
        }
    }
    .gameBox__grid {
        display: flex;
        flex-wrap: wrap;
        margin: -0.5rem;
        transition: 1s cubic-bezier(0.42, 0, 0.19, 1);
        @media (max-width: $tablet) {
            justify-content: center;
        }
        &.__go {
        }
    }
    .gameBox__gridItem {
        width: 6rem;
        height: 6rem;
        // border: 1px solid white;
        margin: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            border: 3px solid #8c8c8c;
            width: 1rem;
            height: 1rem;
            border-radius: 20px;
        }
        &.__correct {
            .gameBox__char {
                &.__white,
                &.__violet,
                &.__pink,
                &.__orange,
                &.__green,
                &.__lightviolet {
                    color: $color_white;
                    .image svg * {
                        fill: $color_green;
                    }
                }
            }
        }
    }
    .gameBox__stock {
        position: relative;
        // padding-top: 20%;
        display: flex;
        align-items: center;
        // height: 100%;
        width: 100%;
        padding: 10%;
        padding-top: 19%;
        transition: 0.4s;
    }
    .gameBox__stockInner {
        position: relative;
        aspect-ratio: 1/1;
        width: 100%;
        // background: transparentize($color_gray, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        // transition: 0.4s;
        border-radius: 1rem;
        &.__highlight {
            &+.gameBox__stock_image {
                // opacity: 0.5;
            }
            // background: transparentize($color_gray, 0.7);
        }
    }
    .gameBox__stockList {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .gameBox__stockItem {
        width: 6rem;
        height: 6rem;
        // border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: 0.4s;
        z-index: 210;
    }
    .gameBox__char {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: grab;
        user-select: none;
        z-index: 10;
        &.__white {
            color: $color_green;
            .image svg * {
                fill: $color_white;
            }
        }
        &.__violet {
            color: $color_violet_light;
            .image svg * {
                fill: $color_violet_dark;
            }
        }
        &.__pink {
            color: $color_red;
            .image svg * {
                fill: $color_pink;
            }
        }
        &.__orange {
            color: $color_red;
            .image svg * {
                fill: $color_orange;
            }
        }
        &.__green {
            color: $color_yellow;
            .image svg * {
                fill: $color_green;
            }
        }
        &.__lightviolet {
            color: $color_violet_dark;
            .image svg * {
                fill: $color_violet_light;
            }
        }
        .image {
            position: absolute;
            pointer-events: none;
            filter: drop-shadow(2px 2px 8px transparentize($color_black, 0.5));
            svg {
                // max-width: none;
            }
        }
        .gameBox__char_text {
            z-index: 20;
            pointer-events: none;
        }
    }
    .gameBox__stock_image {
        position: absolute;
        // top: 0;
        // bottom: 0;
        top: -51%;
        right: -41%;
        left: -10%;
        bottom: -30%;
        display: flex;
        justify-content: center;
        pointer-events: none;
        // max-width: 110%;
        // display: none;
        z-index: 202;
        opacity: 0;
        transition: .2s;
        will-change: opacity;
        @media (max-width: $tablet) {
            top: -50%;
            right: -50%;
            left: -16%;
            bottom: -30%;
            mask-image: linear-gradient(to bottom, transparent 25%, black 40%, black);
        }
        .image {
            max-width: 100%;
            height: 100%;
        }
    }

    .grid {
        display: flex;
        flex-wrap: wrap;

        .place {
            width: 6rem;
            height: 6rem;
            // border: 1px solid white;
            margin: 0;
            transition: 0.2s;
            border-radius: 1rem;
            &.__highlight {
                background-color: gray;
            }
            &.__changing {
                .gameBox__char {
                    transform: scale(0.5);
                    opacity: 0;
                }
            }
            &.__blocked {
                pointer-events: none;
                border: 1px solid $color_green;
            }
            // &.__correct {
            //     background-color: green;
            // }
        }
    }

    .stock {
        // display: flex;
        // flex-wrap: wrap;
    }
    .gameBox__char {
        width: 6rem;
        height: 6rem;
        transition: 0.4s;
        // border: 1px solid lightgray;
        transform: scale(0.5);
        opacity: 0;
        will-change: transform;
        // pointer-events: none;
        &.__loaded {
            opacity: 1;
            transform: none;
        }
        &.__dragging {
            transition: transform 0s !important;
            pointer-events: none;
            z-index: 200;
        }
    }
    .gameBox__gameTipContainer {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        pointer-events: none;
        z-index: 230;
        .gameTip {
            width: 100%;
            max-height: 50vh;
        }
    }
    .gameBox__gameMessContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 230;
        .gameMess {
            height: calc(100% - 8rem);
        }
    }
}

.gameTip {
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $color_green;
    color: $color_white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    transition: 0.6s ease-in-out;
    transform: translateY(100%);
    pointer-events: all;
    &.__active {
        transform: none;
    }
    &.__visible {
        .gameTip__body {
            opacity: 1;
        }
    }
    .gameTip__close {
        position: absolute;
        right: 2rem;
        top: 2rem;
        z-index: 20;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
        @include t2();
        font-size: 0rem;
        transition: all 0.2s;
        &:hover {
            font-size: 1rem;
            opacity: 0.8;
            transition: all 0.2s, opacity 0.05s;
            .icon {
                transform: rotate(90deg);
            }
        }
        &:active {
            transition: all 0.2s, opacity 0.02s;
            opacity: 0.6;
        }
        .icon {
            margin-left: 1rem;
            transition: 0.2s;
        }
    }
    .gameTip__body {
        position: relative;
        transition: 0.2s;
        opacity: 0;
        @include sideFaders(2rem, $color_green, 0, true);
    }
    .gameTip__scroller {
        @include scrollWithoutScroll();
        padding: 2rem 0;
        max-height: 100%;
    }
    .gameTip__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
    .gameTip__icon {
    }
    .gameTip__counter {
        @include h3();
    }
    .gameTip__text {
        @include t2();
    }
    .gameTip__actions {
        display: flex;
        justify-content: center;
        .btn {
            color: $color_white;
        }
    }
}
.gameMess {
    position: relative;
    z-index: 30;
    width: 100%;
    display: flex;
    transform: translateY(120vh);
    pointer-events: all;
    transition: 1s cubic-bezier(0.42, 0, 0.19, 1);
    // opacity: 0;
    scale: 0.8;
    &.__active {
        transform: none;
    }
    &.__inited {
        scale: 1;
        .gameMess__inner {
            opacity: 1;
        }
        // opacity: 1;
    }
    .gameMess__outer {
        display: flex;
        width: 100%;
        .gameMess__mark {
            position: absolute;
            right: 2rem;
            top: 0;
            z-index: 20;
        }
    }
    .gameMess__body {
        width: 100%;
        border-radius: 1rem;
        border: 2px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.08);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(16px);
        position: relative;
        padding-right: 3rem;
        padding-left: 2rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: flex;
        flex-direction: column;
    }
    .gameMess__scroller {
        @include scrollWithoutScroll();
        padding: 2rem 0;
        flex-basis: 100%;
        mask-image: linear-gradient(to bottom, transparent, black 2rem, black calc(100% - 2rem), transparent);
    }

    .gameMess__inner {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        opacity: 0.1;
        transition: 0.2s;
    }

    .gameMess__actions {
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: space-between;
        height: 0;
        bottom: 0;
        left: 2rem;
        right: 2rem;
    }

    .gameMess__title {
        @include h1();
        color: $color_green;
        hyphens: auto;
        hyphenate-character: "";
        -webkit-hyphens: auto;
        -webkit-hyphenate-character: "";
        max-width: 100%;
    }
    .gameMess__text {
        @include t2();
    }
}

.exampleChar {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    color: $color_green;
    opacity: 0;
    pointer-events: none;
    transition: 0.2;
    svg {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    span {
        position: relative;
        @include h2();
    }
}

@keyframes pulse {
    0% {
        scale: 1;
        opacity: 1;
    }
    50% {
        scale: 0.9;
        opacity: 0.6;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}

.gameMainTip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 1rem;
    background-color: $color_orange;
    color: $color_white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    z-index: 20;
    pointer-events: none;
    opacity: 0;
    transform: scale(0.5);
    transition: .6s ease-in-out;
    &.__active {
        pointer-events: all;
        opacity: 1;
        transform: none;
    }
    .gameMainTip__head {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    .gameMainTip__body {
        margin-top: 2rem;
    }
    .gameMainTip__close {
        cursor: pointer;
        transition: .2s;
        &:hover {
            transform: rotate(90deg);
        }
    }
}