@import "vars";
@import "fonts";
@import "base";
@import "typo";

html {
    background-image: url("../../public/assets/bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: $color_bg;
    background-attachment: fixed;

    color: $color_white;
    font-size: 16px;
    @include t1();
    text-transform: none;

    @media (max-width: $desktop) {
        font-size: 14px;
    }
    @media (max-width: $tablet) {
        font-size: 12px;
    }
    @media (max-width: $mobile) {
        font-size: 10px;
    }
}
body {
    background: none;
}

.container {
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: $desktop;
    max-width: 100vw;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
    @media (max-width: $tablet) {
        padding-left: 1rem;
        padding-right: 1rem;
        // max-width: 1200px;
    }

    & > * {
        &:not(:first-child) {
            margin-top: 2rem;
        }
    }
}
