@import "../../css/vars";
@import "../../css/typo";

.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    .container {
        justify-content: center;
    }
}

.pages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    transition: 0.6s;
    opacity: 0;
    &.__in {
        opacity: 0;
    }
    &.__done {
        opacity: 1;
    }
}
