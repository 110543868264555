
@mixin h1() {
    font-size: 3.75rem;
    line-height: 1.2;
    font-weight: bold;
    font-style: normal;
    font-family: $fontAlt;
    text-transform: uppercase;
}
@mixin h2() {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: bold;
    font-style: normal;
    font-family: $fontAlt;
    text-transform: uppercase;
}
@mixin h3() {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: bold;
    font-style: normal;
    font-family: $fontAlt;
    text-transform: uppercase;
}
@mixin h4() {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: bold;
    font-style: normal;
    font-family: $fontAlt;
    text-transform: uppercase;
}

@mixin tNumbers() {
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: bold;
    font-style: normal;
    font-family: $fontAlt;
}

@mixin t1() {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
    text-transform: uppercase;
}

@mixin t2() {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
}

@mixin t3() {
    font-size: 2.75rem;
    line-height: 1.2;
    font-weight: normal;
    font-style: normal;
    font-family: $font;
}