@import "../../css/vars";
@import "../../css/typo";


.page.choice {
    position: relative;
    padding-bottom: 10vw;
    justify-content: center;
    &.__loaded {
        .list {
            & > * {
                transform: none;
                opacity: 1;
            }
        }
        .item {
            .image {
                transform: none;
                opacity: 1;
            }
        }
    }
    // &.__overloaded {
    //     .topicItem {
    //         backdrop-filter: blur(2rem);
    //     }
    // }
    .botBg {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transition: .4s;
        transform: translateY(100%);
        opacity: 0;
        max-height: 40vh;
        display: flex;
        justify-content: center;
    }
    .list {
        display: flex;
        @include actions();
        margin-top: 1.5rem;
        @media (max-width: $tablet) {
            flex-direction: column;
        }
        & > * {
            flex-basis: 100%;
            transform: scale(0.5);
            opacity: 0;
        }
    }
    .item {
        display: flex;
        transition: 0.4s;
        @for $i from 1 to 5 {
            $total: 5;
            &:nth-child(#{$total - $i}){
                z-index: $i;
            }
            &:nth-child(#{$i}){
                transition-delay: $i / 10 + s;
            }
        }
        .image {
            transform: scale(0);
            opacity: 0;
            transition: .2s;
        }
        // will-change: opacity;
    }
    .actions {
        display: flex;
        @include actions();
        margin-top: calc(2rem + 2vmax);
        justify-content: center;
        // padding-left: 20%;
    }
}
