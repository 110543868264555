$color_white: #fff;
$color_gray: #8C8C8C;
$color_black: #000000;

$color_green: #18C16D;
$color_green_dark: #056736;
$color_pink: #FEAED8;
$color_red: #941F46;
$color_orange: #F68356;
$color_yellow: #EDF1A7;
$color_violet_light: #C2C7FB;
$color_violet_dark: #3D3053;

$color_bg: $color_black;

$color_border: rgba(255, 255, 255, 0.2);

$color_primary: $color_green;
$color_primary_hover: lighten($color_primary, 10%);
$color_primary_active: darken($color_primary, 10%);

// $color_border: $color_primary;

$color_selection_text: white;
$color_selection_bg: $color_primary;

$color_placeholder: $color_gray;

$header_height: 114px;
$header_height_mob: 80px;

$container_width: 1140px;
$sidebar_width: 260px;

$desktop: 1440px;
$tablet: 820px;
$tablet_min: 821px;
$mobile: 495px;
$mobile_min: 496px;

$font: "ABC Favorit", "Helvetica", sans-serif;
$fontAlt: "Grato Grotesk", "Helvetica", sans-serif;

@mixin scrollWithoutScroll() {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}
@mixin scrollWithoutScrollHorizontal() {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-height: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        height: 0px;
        background: transparent;
    }
}
@mixin transition() {
    transition: 0.2s;
    &:hover {
        transition: 0.05s;
    }
    &:hover {
        transition: 0.02s;
    }
}

@mixin sideFaders($width: 12px, $color: $color_white, $inset: 0%, $isTopBottom: false) {
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        pointer-events: none;
        z-index: 2;
        @if($isTopBottom){
            height: $width;
            left: 0;
            right: 0;
        } @else {
            width: $width;
            top: 0;
            bottom: 0;
        }
    }
    &:before {
        @if($isTopBottom){
            background: linear-gradient(to bottom, $color $inset, transparentize($color, 1));
            top: 0;
        } @else {
            background: linear-gradient(to right, $color $inset, transparentize($color, 1));
            left: 0;
        }
    }
    &:after {
        @if($isTopBottom){
            background: linear-gradient(to top, $color $inset, transparentize($color, 1));
            bottom: 0;
        } @else {
            background: linear-gradient(to left, $color $inset, transparentize($color, 1));
            right: 0;
        }
    }
}

@mixin actions($margin: 0.5rem){
    margin: -$margin;
    &>*{
        margin: $margin;
    }
}

