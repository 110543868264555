
.image {
    display: flex;
    img {
        max-width: 100%;
        height: auto;
    }
    .image__svg {
        max-width: 100%;
        display: flex;
        svg {
            max-width: 100%;
            height: auto;
        }
    }
}