@import "../../css/vars";
@import "../../css/typo";

.dict {
    display: flex;
    gap: 1rem;
    max-height: calc(100dvh - $header_height);
    overflow: hidden;
    @media(max-width: $tablet){
        flex-direction: column;
        max-height: none;
        overflow: visible;
    }
    .dict__col {
        &:first-child {
            min-width: calc(40% - 0.5rem);
            max-width: calc(40% - 0.5rem);
            @media(max-width: $tablet){
                min-width: 100%;
                max-width: 100%;
            }
        }
        &:last-child {
            min-width: calc(60% - 0.5rem);
            max-width: calc(60% - 0.5rem);
            @media(max-width: $tablet){
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
    .dict__message {
        // position: sticky;
        max-height: calc(100% - 8rem);
        display: flex;
        @media(max-width: $tablet){
            max-height: none;
        }
    }
    .dict__scroller {
        margin-top: -2rem;
        max-height: calc(100% + 2rem);
        @include scrollWithoutScroll();
        padding: 2rem 0;
        flex-basis: 100%;
        // mask-image: linear-gradient(to bottom, transparent, black 2rem, black calc(100% - 2rem), transparent);
    }
    .dict__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
.dictItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 1rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(16px);
    .dictItem__title {
        text-align: center;
    }
    .dictItem__list {
        display: flex;
        justify-content: center;
        gap: 1rem;
        max-width: 90%;
        & > * {
            flex-basis: 100%;
            width: 20%;
        }
    }
}
.dictInnerItem {
    cursor: pointer;
    @include transition();
    user-select: none;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.6;
    }
    &:not(.__active) {
        .dictInnerItem__image {
            filter: grayscale(1) brightness(1.2);
        }
    }
    .dictInnerItem__image {
        transition: 0.4s;
        will-change: filter;
    }
    .dictInnerItem__word {
        text-align: center;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            hyphens: auto;
            hyphenate-character: "";
            -webkit-hyphens: auto;
            -webkit-hyphenate-character: "";
            max-width: 100%;
        }
    }
}
