@import "../../css/vars";
@import "../../css/typo";



.topicItem {
    padding: 3rem;
    border: 2px solid $color_border;
    border-radius: 0.5rem;
    backdrop-filter: blur(2rem);
    cursor: pointer;
    user-select: none;
    will-change: opacity;
    position: relative;
    @include transition();
    &:hover {
        background-color: transparentize($color_green, 0.8);
        .topicItem__levels {
            transition: .05s;
        }
    }
    &:active {
        background-color: transparentize($color_green, 0.6);
        .topicItem__levels {
            transition: .02s;
        }
    }
    &.__selected {
        transition: .2s;
        background-color: transparentize($color_green, 0.1);
        border-color: $color_white;
        box-shadow: 0 4px 8rem -2rem $color_green;
        .topicItem__levels {
            color: $color_white;
        }
    }
    &.__completed {
        border-color: $color_green;
        // pointer-events: none;
    }
    & > * {
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
    .topicItem__levels {
        color: $color_gray;
        line-height: 1;
        margin-top: 1.2rem;
        transition: .2s;
    }
    .image {
        position: absolute;
        right: -1rem;
        bottom: -2.5rem;
        width: 10rem;
        filter: drop-shadow(0px 2px 8px transparentize($color_black, 0.5))
    }
}
