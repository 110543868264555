* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}
*::-moz-selection {
    color: $color_selection_text;
    background: $color_selection_bg;
}
*::selection {
    color: $color_selection_text;
    background: $color_selection_bg;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
input::-moz-placeholder {
    color: $color_placeholder;
}
input::-webkit-input-placeholder {
    color: $color_placeholder;
}
input:-ms-input-placeholder {
    color: $color_placeholder;
}
input::-ms-input-placeholder {
    color: $color_placeholder;
}
input::placeholder {
    color: $color_placeholder;
}
input {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea,
input {
    font-family: $font;
    font-size: 14px;
    line-height: 14px;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
}
div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
section {
    position: relative;
}
body {
    min-width: 360px;
    // touch-action: pan-x pan-y;
    touch-action: pan-y;
    scroll-behavior: smooth;
    overscroll-behavior: none;
    font-feature-settings: "ss06";
}
#__site_wrapper {
    overflow: hidden;
    min-height: 100svh;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    position: relative;    
    &.__inited {
        main {
            opacity: 1;
        }
    }
}

main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: $header_height;
    transition: 0.4s;
    opacity: 0;
    
    @media (max-width: $tablet) {
        padding-top: $header_height_mob;
    }
}

a {
    text-decoration: none;
    color: $color_primary;
    @include transition();
    &:focus,
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.6;
    }
    &.__self {
        // display: inline;
        border-bottom: 1px dashed $color_primary;
    }
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

