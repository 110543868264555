@import "../../css/vars";
@import "../../css/typo";

.page.index {
    position: relative;
    padding-bottom: 10vw;
    .botBg {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 40vh;
        display: flex;
        justify-content: center;
    }
    .actions {
        display: flex;
        @include actions();
        margin-top: calc(2rem);
        justify-content: center;
        align-items: center;
        // padding-left: 20%;
        @media(max-width: $mobile){
            flex-direction: column;
        }
        .btn {
            &.__reset {
                color: $color_white;
            }
            &.__continue {
                color: $color_primary;
            }
        }
    }
}