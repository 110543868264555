@import "../../css/vars";
@import "../../css/typo";

.miniShare {
    display: flex;
    flex-direction: column;
    &.__copied {
        .miniShare__box {
            ._copied {
                opacity: 1;
            }
        }
    }
    .miniShare__box {
        color: $color_green;
        background-color: $color_white;
        border-radius: 0.5rem;
        padding: 1rem;
        padding-right: 4rem;
        display: flex;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        @include transition();
        &:hover {
            background-color: darken($color_white, 8%);
        }
        &:active {
            background-color: darken($color_white, 16%);
        }
        .miniShare__link {
            @include t2();
            max-width: 100%;
            overflow: hidden;
            line-height: 1;
            text-overflow: ellipsis;
            display: inline-block;
            white-space: nowrap;
        }
        ._copy {
            width: 2rem;
            height: 2rem;
            margin-left: 1rem;
            position: absolute;
            right: 1rem;
            top: 0;
            bottom: 0;
            margin: auto;
            .icon {
                width: 100%;
                height: 100%;
            }
        }
        ._copied {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            transition: .2s;
            user-select: none;
            pointer-events: none;
            @include t2();
            line-height: 1;
            color: $color_white;
            background-color: $color_green;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 2px solid $color_white;
            border-radius: 0.5rem;
        }
    }
    .miniShare__bot {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .miniShare__text {
        @include t2();
        margin-right: 1rem;
    }
    .miniShare__actions {
        display: flex;
        @include actions();
        & > a {
        }
    }
}
