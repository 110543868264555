@font-face {
    font-family: "ABC Favorit";
    src: url("../fonts/ABCFavoritPro-Book.otf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Grato Grotesk";
    src: url("../fonts/GratoGroteskDEMO-Bold.otf");
    font-weight: bold;
    font-style: normal;
}