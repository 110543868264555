@import "../../css/vars";
@import "../../css/typo";

.page.share {
    position: relative;
    min-height: calc(100svh - #{$header_height});
    padding: 2rem 0 0;
    @media(max-width: $tablet){
        min-height: calc(100svh - #{$header_height_mob});
    }
    .container {
        flex-grow: 1;
    }
}

.shareBox {
    flex-grow: 1;
    display: flex;
    width: 100%;
    .shareBox__row {
        display: flex;
        width: 100%;
    }
    .shareBox__col {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        &:first-child {
            @media(max-width: $tablet){
                display: none;
            }
        }
        &:last-child {
            min-width: 400px;
            max-width: 600px;
            padding-left: 2rem;
            padding-right: 2rem;
            @media(max-width: $tablet){
                max-width: 100%;
                min-width: 320px;
            }
        }
    }
    .shareBox__lottieBox {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10vw;
    }
    .shareBox__lottie {
        width: 80%;
        max-width: 600px;
    }
    .shareBox__image {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        img {
            width: 100%;
        }
    }
    .shareBox__gameMess {
        flex-grow: 1;
        .gameMess {
            height: calc(100% - 8rem);
        }
    }
}
