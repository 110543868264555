@import "../../css/vars";
@import "../../css/typo";

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    user-select: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    &:before {
        content: "";
        position: absolute;
        height: 120%;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(to bottom, black, transparent);
    }
    .container {
        position: relative;
    }

    .header__inner {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        height: $header_height;
        @media (max-width: $tablet) {
            height: $header_height_mob;
        }
    }
    .header__col {
        flex-basis: 100%;
        display: flex;
        &:last-child {
            justify-content: flex-end;
        }
        &.__logo {
            @media (max-width: $mobile) {
                max-width: 72px;
            }
            a {
                pointer-events: all;
            }
            .image {
                padding: 0.75rem 0;
                max-height: 100%;
                svg {
                    width: auto;
                }
            }
        }
        &.__actions {
            align-items: flex-start;
            overflow: hidden;
            margin-top: 1px;
            @media (max-width: $tablet) {
                margin-top: 0;
            }
        }
        &.__info {
            padding: 1.5rem 0;
            align-items: flex-start;
            @media (max-width: $mobile) {
                padding: 1.5rem 0;
                max-width: 110px;
            }
        }
    }

    .gameAction__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        gap: 0.5rem;
        transform: translateY(-105%);
        pointer-events: none;
        transition: 0.2s;
        &.__active {
            pointer-events: all;
            transform: none;
        }
        &.__learn_2 {
            .gameAction__item {
                animation: pulseHighlight 1s infinite
            }
        }
        .gameAction__item {
            border-radius: 0px 0px 16px 16px;
            background: rgba(255, 255, 255, 0.15);
            backdrop-filter: blur(16px);
            min-height: 4rem;
            min-width: 6rem;
            padding-top: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transform: translateY(-0.5rem);
            transition: 0.2s;
            &:hover {
                background: rgba(255, 255, 255, 0.338);
                transition: transform 0.2s, all 0.05s;
                transform: none;
            }
            &:active {
                background: rgba(255, 255, 255, 0.508);
                transition: transform 0.2s, all 0.02s;
            }
            &.__disabled {
                filter: grayscale(0.8);
                pointer-events: none;
            }
            &.__tip {
                @include tNumbers();
            }
        }
    }
    .gameInfo__list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: 0.2s;
        margin-right: 2rem;
        transform: translateX(-10%);
        pointer-events: none;
        opacity: 0;
        gap: 1.5rem;
        @media (max-width: $mobile) {
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            margin-right: 1.5rem;
        }
        &.__active {
            pointer-events: all;
            transform: none;
            opacity: 1;
        }
        .gameInfo__item {
            color: $color_white;
            @include t1();
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: $mobile) {
                width: 100%;
            }
            span {
                margin-left: 0.75rem;
                @include tNumbers();
                color: $color_pink;
                line-height: 1;
                margin-top: -0.4rem;
                @media (max-width: $mobile) {
                    margin-left: 0.5rem;
                }
            }
        }
    }
    .burger {
        width: 2.25rem;
        height: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        pointer-events: all;
        @include transition();
        color: $color_white;
        // @media (max-width: $mobile) {
        //     margin-top: 1rem;
        // }
        &:hover {
            color: lighten($color_green, 40%);
        }
        &:active {
            color: lighten($color_green, 20%);
        }
        &.__active {
            span {
                &:nth-child(1) {
                    transform: translateY(-4px);
                    opacity: 0;
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
                &:nth-child(3) {
                    transform: rotate(45deg);
                }
                &:nth-child(4) {
                    transform: translateY(4px);
                    opacity: 0;
                }
            }
        }
        span {
            position: absolute;
            left: 0;
            right: 0;
            height: 0.15rem;
            border-radius: 20px;
            background-color: currentColor;
            transition: 0.2s;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:nth-child(4) {
                bottom: 0;
            }
        }
    }
}

@keyframes pulseHighlight {
    0% {
        transform: translateY(-0.5rem);
        background: rgba(255, 255, 255, 0.15);
    }
    50% {
        background-color: $color_gray;
        transform: translateY(0);
    }
    100% {
        background: rgba(255, 255, 255, 0.15);
        transform: translateY(-0.5rem);
    }
}