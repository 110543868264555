@import "../../css/vars";
@import "../../css/typo";


.menu {
    position: absolute;
    top: calc($header_height / 2);
    right: 2rem;
    z-index: 120;
    border-radius: 1rem;
    background-color: $color_green;
    padding: 1rem;
    user-select: none;
    transform-origin: top right;
    pointer-events: none;
    transition: .2s;
    transform: scale(0);
    &.__active {
        pointer-events: all;
        transform: none;
    }
    &.__inModal {
        position: relative;
        top: 0;
        right: 0;
        background: none;
        transform: none;
        padding: 0;
        .menu__item {
            padding: 0.5rem 0;
        }
    }
    .menu__list {
        display: flex;
        flex-direction: column;
    }
    .menu__item {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        color:$color_green_dark;
        @include transition();
        &:hover {
            color: $color_white;
        }
        &:active {
            color: lighten($color_green_dark, 40%);
        }

        .icon {
            max-width: 2rem;
            min-width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            svg {
                max-width: 100%;
                max-height: 100%;
                &>* {
                    stroke: currentColor
                }
            }
        }
        .title {
            color: currentColor
        }
    }
}
