@import "../../css/vars";
@import "../../css/typo";

.page.notFound {
    position: relative;
    padding-bottom: 10vw;
    .botBg {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        max-height: 40vh;
        display: flex;
        justify-content: center;
    }
    .notFoundBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .notFoundBox__box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 1rem;
            .btn.__secondary {
                color: $color_green;
            }
        }
    }
}
.page {
}